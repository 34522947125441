module.exports = {
  defaultTitle: 'Guappe',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Guappe',
  url: 'https://Guappe.com',
  legalName: 'Guappe',
  defaultDescription: 'Guappe la nueva APP de Delivery',
  socialLinks: {
    twitter: 'http://www.twitter.com/guappe',
    github: 'https://github.com/guappe',
    linkedin: 'https://www.linkedin.com/in/guappe/',
    instagram: 'https://instagram.com/guappe',
    youtube: 'https://www.youtube.com/user/guappe',
  },
  googleAnalyticsID: 'UA-189027934-1',
  themeColor: '#4da9ff',
  backgroundColor: '#4da9ff',
  social: {
    facebook: 'appId',
    twitter: '@smakosh',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2020',
};
